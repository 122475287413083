import * as THREE from 'three'
import { useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useIntersect, Image, ScrollControls, Scroll } from '@react-three/drei'

function Item({ url, scale, ...props }) {
  const visible = useRef(false)
  const [hovered, hover] = useState(false)
  const ref = useIntersect((isVisible) => (visible.current = isVisible))
  const { height } = useThree((state) => state.viewport)
  useFrame((state, delta) => {
    ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? 0 : -height / 2 + 1, 4, delta)
    ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 1.5, 4, delta)
    ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, hovered ? 0 : 1, 4, delta)
  })
  return (
    <group {...props}>
      <Image ref={ref} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} scale={scale} url={url} />
    </group>
  )
}

function Items() {
  const screensize = useRef();
  const { width: w, height: h } = useThree((state) => state.viewport)
  screensize.h = window.innerWidth > 819 ? h : h / 1.5
  screensize.w = window.innerWidth > 819 ? w : w / 0.8

  return (
    <Scroll>
      <Item url="/1.jpg" scale={[screensize.w / 2.5, screensize.w / 2.5, 1]} position={[-screensize.w / 6, 1, 0]} />
      <Item url="/4.jpg" scale={[2, screensize.w / 3, 1]} position={[screensize.w / 30, -screensize.h * 0.9, 0]} />
      <Item url="/6.jpg" scale={[screensize.w / 3, screensize.w / 5, 1]} position={[-screensize.w / 4, -screensize.h * 1.2, 0]} />
      <Item url="/8.jpg" scale={[screensize.w / 5, screensize.w / 5, 1]} position={[screensize.w / 4, -screensize.h * 1, 0]} />
      <Item url="/10.jpg" scale={[screensize.w / 5, screensize.w / 5, 1]} position={[screensize.w / 10, -screensize.h * 1.85, 0]} />
      <Item url="/2.jpg" scale={[screensize.w / 3, screensize.w / 3, 1]} position={[-screensize.w / 4, -screensize.h * 2, 0]} />
      <Item url="/3.jpg" scale={[screensize.w / 3, screensize.w / 5, 1]} position={[-screensize.w / 4, -screensize.h * 2.9, 0]} />
      <Item url="/11.jpg" scale={[screensize.w / 2, screensize.w / 2, 1]} position={[screensize.w / 4, -screensize.h * 2.8, 0]} />
      <Item url="/7.jpg" scale={[screensize.w / 2.5, screensize.w / 2, 1]} position={[-screensize.w / 6, -screensize.h * 3.8, 0]} />
    </Scroll>
  )
}

function Texts() {
  const topPos = window.innerWidth > 819 ? 1 : 0.8;
  const fontSize = window.innerWidth > 819 ? "30em" : "20em";

  return (
    <Scroll html style={{ width: '100%' }}>
      <h1 style={{ position: 'absolute', top: (90 * topPos).toString() + "vh", right: '16vw', fontSize: fontSize, transform: `translate3d(0,-100%,0)` }}>hi</h1>
      <h1 style={{ position: 'absolute', top: (130 * topPos).toString() + "vh", left: '10vw' }}>we</h1>
      <h1 style={{ position: 'absolute', top: (180 * topPos).toString() + "vh", right: '10vw' }}>always</h1>
      <h1 style={{ position: 'absolute', top: (290 * topPos).toString() + "vh", left: '10vw' }}>think</h1>
      <h1 style={{ position: 'absolute', top: (380 * topPos).toString() + "vh", right: '10vw' }}>
        of
        <br />
        you.
      </h1>
    </Scroll>
  )
}

function App() {
  const pages = window.innerWidth > 819 ? 5 : 4;
  return (
    <Canvas orthographic camera={{ zoom: 80 }} gl={{ alpha: false, antialias: false, stencil: false, depth: false }} dpr={[1, 1.5]}>
      <color attach="background" args={['#f0f0f0']} />
      <ScrollControls damping={5} pages={pages}>
        <Items />
        <Texts />
      </ScrollControls>
    </Canvas>
  )
}

export default App;